import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import SEO from "../components/seo";
import Container from "../components/container";
import Elevator from "../components/elevator";
import Layout from "../components/layout";
import "../styles/layout.scss";

import arrowRight from "../assets/arrow-right.svg";

export const query = graphql`
  query PriceQuery {
    coachingPage: allSanityPage(filter: { name: { eq: "Priser" } }) {
      edges {
        node {
          name
          _rawBody
          _rawExtraBody
          _id
          image {
            alt
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
        }
      }
    }
    bikeImage: allSanityPage(filter: { name: { eq: "Sykkelcoaching" } }) {
      edges {
        node {
          image {
            alt
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
        }
      }
    }

    runImage: allSanityPage(filter: { name: { eq: "Løpecoaching" } }) {
      edges {
        node {
          image {
            alt
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
        }
      }
    }

    runPackages: allSanityRunPtPackage {
      edges {
        node {
          _id
          title
          price
        }
      }
    }

    cyclePackages: allSanityCyclePtPackage {
      edges {
        node {
          _id
          title
          price
        }
      }
    }
  }
`;

const PricesPage = () => {
  const data = useStaticQuery(query);
  const pageEdge = mapEdgesToNodes(data.coachingPage);
  const elevatorContent = pageEdge[0] || [];

  const bikeImageArr = mapEdgesToNodes(data.bikeImage);
  const runImageArr = mapEdgesToNodes(data.runImage);

  const bikeImage = bikeImageArr[0].image || [];
  const runImage = runImageArr[0].image || [];

  const runPackages = mapEdgesToNodes(data.runPackages);
  const cyclePackages = mapEdgesToNodes(data.cyclePackages);

  return (
    <Layout>
      <SEO title="Priser" />
      <div className="elevator__background"></div>

      <Container>
        <div className="prices-page">
          <Elevator
            title={elevatorContent.name}
            image={elevatorContent.image}
            info={elevatorContent._rawBody}
            extraInfo={elevatorContent._rawExtraBody}
          />
          <div className="prices-page__prices-wrapper">
            <div className="prices-page__prices">
              <h3 className="prices-page__prices-title">Løpecoaching</h3>
              {runImage && runImage.asset && (
                <img
                  className="prices-page__prices-img"
                  src={imageUrlFor(buildImageObj(runImage))
                    .width(600)
                    .height(600)
                    .fit("crop")
                    .auto("format")
                    .url()}
                  alt={runImage.alt}
                />
              )}
            </div>
            <div className="prices-page__prices">
              <h4 className="prices-page__prices-heading">PT-Pakker</h4>
              <div className="prices-page__prices-container">
                {runPackages &&
                  runPackages.map(pack => (
                    <div key={pack._id} className="prices-page__pack">
                      <span className="prices-page__pack-title">{pack.title}</span>
                      <span className="prices-page__pack-seperator">&#9472;</span>
                      <span className="prices-page__pack-price">{pack.price},-</span>
                    </div>
                  ))}
              </div>
              <Link className="prices-page__link" to="/coaching/lopecoaching">
                <span className="prices-page__link-text">Se løpe pakkene</span>
                <img src={arrowRight} alt="gå videre til om pt pakkene" />
              </Link>
            </div>
          </div>
          <div className="prices-page__prices-wrapper">
            <div className="prices-page__prices">
              <h3 className="prices-page__prices-title">Sykkelcoaching</h3>

              {bikeImage && bikeImage.asset && (
                <img
                  className="prices-page__prices-img"
                  src={imageUrlFor(buildImageObj(bikeImage))
                    .width(500)
                    .height(500)
                    .fit("crop")
                    .auto("format")
                    .url()}
                  alt={bikeImage.alt}
                />
              )}
            </div>
            <div className="prices-page__prices">
              <h4 className="prices-page__prices-heading">PT-Pakker</h4>
              <div className="prices-page__prices-container">
                {cyclePackages &&
                  cyclePackages.map(pack => (
                    <div key={pack._id} className="prices-page__pack">
                      <span className="prices-page__pack-title">{pack.title}</span>
                      <span className="prices-page__pack-seperator">&#9472;</span>
                      <span className="prices-page__pack-price">{pack.price},-</span>
                    </div>
                  ))}
              </div>
              <Link className="prices-page__link" to="/coaching/sykkelcoaching">
                <span className="prices-page__link-text">Se sykkel pakkene</span>
                <img src={arrowRight} alt="gå videre til om pt pakkene" />
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default PricesPage;
